


























import SourceDataMixin from '../mixins/SourceDataMixin'
import Panel from '@/components/Panel.vue'

export default SourceDataMixin.extend({
  components: {
    Panel
  },
  created() {
    this.getFilterCounts()
  }
})
