
































































import ProcessesMixin from '../mixins/ProcessesMixin'
import Panel from '@/components/Panel.vue'

export default ProcessesMixin.extend({
  components: {
    Panel
  },
  methods: {
    getRoute(item, filter: string) {
      return {
        name: 'Import',
        params: { id: item.processRunId },
        query: { processId: item.processId, filter }
      }
    }
  },
  created() {
    this.getProcessInsights()
  }
})
