<template>
  <Panel
    title="Lifecycle Listings"
    titleIcon="$list"
    class="fill-height"
    :loading="loading"
    skeleton-loader="list-item@12"
    :no-data="!families.length"
  >
    <template #actions>
      <router-link
        class="mt-3"
        :to="{ name: 'Listings', query: { filter: 'All' } }"
        >View All Listings</router-link
      >
    </template>
    <v-list class="list" v-if="families.length">
      <div v-for="(family, index) in families" :key="family.id">
        <v-list-group no-action>
          <template #activator>
            <v-list-item-title v-text="family.name" />
          </template>

          <template v-for="group in family.$_groups">
            <div :key="group.id">
              <v-list-group
                sub-group
                no-action
                :disabled="!group.$_products.length"
              >
                <template #activator>
                  <v-list-item :disabled="!group.$_products.length">
                    <v-list-item-title v-text="group.name" />
                    <v-list-item-action>
                      <v-btn
                        :disabled="!group.$_products.length"
                        color="primary"
                        small
                        outlined
                        text
                        :to="{
                          name: 'Listings',
                          query: { parentId: group.id, filter: 'All' }
                        }"
                        >View</v-btn
                      >
                    </v-list-item-action>
                  </v-list-item>
                </template>

                <template v-for="product in group.$_products">
                  <v-list-item :key="product.id" @click.stop>
                    <v-list-item-title>{{ product.name }}</v-list-item-title>
                    <v-list-item-action class="pr-4">
                      <v-btn
                        color="primary"
                        small
                        outlined
                        text
                        :to="{
                          name: 'Listings',
                          query: { parentId: product.id, filter: 'All' }
                        }"
                        >View</v-btn
                      >
                    </v-list-item-action>
                  </v-list-item>
                </template>
              </v-list-group>
            </div>
          </template>
        </v-list-group>
        <v-divider v-if="index < families.length - 1" />
      </div>
    </v-list>
  </Panel>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import Panel from '@/components/Panel.vue'

const { mapGetters } = createNamespacedHelpers('ListingHierarchies')

export default {
  components: {
    Panel
  },
  computed: {
    ...mapGetters(['families', 'loading'])
  }
}
</script>

<style scoped>
.list {
  height: 775px;
  max-height: 775px;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
