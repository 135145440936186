import ODataMixin from '@/mixins/ODataMixin'
import ProcessInsight from '../models/ProcessInsight'
import ODataResult from '@/models/ODataResult'

export default ODataMixin.extend({
  data() {
    return {
      processInsights: new ODataResult<ProcessInsight>()
    }
  },
  methods: {
    async getProcessInsights() {
      const result = await this.get<ProcessInsight>('processes/insights')
      result.value = result.value.filter(value => value.showOnDashboard === true).map(x => new ProcessInsight(x))
      this.processInsights = result
    }
  }
})
